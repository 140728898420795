'use client'
import cookieCutter from 'js-cookie'
import { useContext, useRef } from 'react'

import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useTracking } from '@/hooks/useTracking'

import { AuthContext } from '@/providers/AuthContext'
import { api } from '@/services/api'
import * as utils from '@/utils'

import { User, UserNotification, UserProfile } from '@/types'

export const useUser = () => {
  const { user, profile, setUser, setProfile, isUserLoggedIn } = useContext(AuthContext)
  const { getItem, setItem, removeItem } = useLocalStorage()

  const tracking = useTracking()

  const notificatonRef = useRef(false)

  const addUser = (user: User) => {
    setUser(user)
    setItem('userInfo', JSON.stringify(user))
  }

  const removeUser = () => {
    setUser(null)
    removeItem('userInfo')
    removeItem('userProfile')
    removeItem('accessToken')
    removeItem('userNotifications')
  }

  const addUserProfile = (profile: UserProfile) => {
    if (profile && profile.id) {
      setProfile(profile)
      setItem('userProfile', JSON.stringify(profile))
    }
  }

  const login = (user: User) => {
    addUser(user)

    tracking.identify(user)

    loadUserProfile()
  }

  const logout = async () => {
    await api.logout()
    removeUser()

    tracking.track({ evt: 'logout' })
    tracking.track({ evt: 'User Logged Out' })
  }

  async function reloadUser() {
    const res = await api.me<User | { data: User }>()
    if (res.kind === 'ok') {
      const userInfo = 'data' in res.data ? res.data.data : res.data
      addUser(userInfo)
      loadUserProfile()
    }
  }

  async function loadUserProfile() {
    const res = await api.userProfile<UserProfile | { data: UserProfile }>()
    if (res.kind === 'ok') {
      const profile = 'data' in res.data ? res.data.data : res.data
      addUserProfile(profile)
    }
  }

  async function loadUserNotifications() {
    const res = await api.getUserPopupNotifications<{ data: [] }>()
    if (res.kind === 'ok') {
      const list: UserNotification[] = []

      if (res.data.data && Array.isArray(res.data.data)) {
        for (const notification of res.data.data) {
          list.push({
            type: notification.data.reason,
            id: notification.id,
          })
        }
      }

      setItem('userNotifications', JSON.stringify(list))
    }
  }

  async function markNotificationAsRead(notificationId: string) {
    if (notificationId) {
      const notifications: UserNotification[] = JSON.parse(getItem('userNotifications') || '[]')
      const index = notifications.findIndex((nty) => nty.id === notificationId)
      if (index > -1) {
        notifications.splice(index, 1)
        await api.notificationMarkAsRead(notificationId)
        setItem('userNotifications', JSON.stringify(notifications))
      }
    }
  }

  function getFreeInviteNotification() {
    const notifications: UserNotification[] = JSON.parse(getItem('userNotifications') || '[]')

    if (notifications && notifications.length > 0) {
      const notification = notifications.find((nty) => nty.type === '3-free-invite-credits')

      if (notification) {
        return notification
      }
    }

    return null
  }

  function subscriptionEnabled() {
    const locationId = cookieCutter.get('NEXT_LOCATION') || ''
    return utils.subscriptionEnabled(locationId)
  }

  return {
    user,
    profile,
    isUserLoggedIn,
    login,
    logout,
    reloadUser,
    loadUserProfile,
    loadUserNotifications,
    markNotificationAsRead,
    getFreeInviteNotification,
    subscriptionEnabled,
  }
}
