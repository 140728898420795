'use client'
import { createContext, useEffect, useMemo, useState } from 'react'

import { useLocalStorage } from '@/hooks/useLocalStorage'

import { api } from '@/services/api'
import EventTracking from '@/services/tracking'

import type { LocationInfo, User, UserNotification, UserProfile } from '@/types'

interface AuthContextInterface {
  user: User | null
  profile: UserProfile | null
  isUserLoggedIn: boolean
  locationInfo: LocationInfo
  setUser: (user: User | null) => void
  setProfile: (profile: UserProfile | null) => void
}

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  profile: null,
  isUserLoggedIn: false,
  locationInfo: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
  setProfile: () => {},
})

export function AuthContextProvider(props: {
  children: React.ReactNode
  isUserLoggedIn: boolean
  eventTracking: EventTracking
  locationInfo: LocationInfo
}) {
  const { isUserLoggedIn, eventTracking, locationInfo } = props

  const { getItem, setItem } = useLocalStorage()

  let storeduser = getItem('userInfo')
  let storeduserProfile = getItem('userProfile')
  const storedAccessToken = getItem('accessToken')

  try {
    if (storeduser) {
      storeduser = JSON.parse(storeduser)
    }

    if (storeduserProfile) {
      storeduserProfile = JSON.parse(storeduserProfile)
    }
  } catch (e) {
    //
  }

  if (storedAccessToken) {
    api.setAuthToken(storedAccessToken)
  }

  const [user, setUser] = useState<User | null>(storeduser || isUserLoggedIn || null)
  const [profile, setProfile] = useState<UserProfile | null>(null)

  const addUser = (user: User) => {
    setUser(user)
    setItem('userInfo', JSON.stringify(user))
  }

  const addUserProfile = (profile: UserProfile) => {
    if (profile && profile.id) {
      setProfile(profile)
      setItem('userProfile', JSON.stringify(profile))
    }
  }

  async function loadUser() {
    const res = await api.me<User | { data: User }>()
    if (res.kind === 'ok') {
      const userInfo = 'data' in res.data ? res.data.data : res.data
      addUser(userInfo)
      eventTracking.identify(userInfo)
      loadUserProfile()
    }
  }

  async function loginWithAccessToken(storedAccessToken: string) {
    const res = await api.loginWithAccessToken<User | { data: User }>(storedAccessToken)
    if (res.kind === 'ok') {
      const userInfo = 'data' in res.data ? res.data.data : res.data
      addUser(userInfo)
      eventTracking.identify(userInfo)
      loadUserProfile()
    }
  }

  async function loadUserProfile() {
    const res = await api.userProfile<UserProfile | { data: UserProfile }>()
    if (res.kind === 'ok') {
      const profile = 'data' in res.data ? res.data.data : res.data
      addUserProfile(profile)
    }

    loadUserNotifications()
  }

  async function loadUserNotifications() {
    const res = await api.getUserPopupNotifications<{ data: [] }>()
    if (res.kind === 'ok') {
      const list: UserNotification[] = []

      if (res.data.data && Array.isArray(res.data.data)) {
        for (const notification of res.data.data) {
          list.push({
            type: notification.data.reason,
            id: notification.id,
          })
        }
      }

      setItem('userNotifications', JSON.stringify(list))
    }
  }

  const value = useMemo(
    () => ({
      isUserLoggedIn,
      user,
      profile,
      locationInfo,
      setUser,
      setProfile,
    }),
    [user, profile],
  )

  useEffect(() => {
    let storeduser = getItem('userInfo')
    const storedAccessToken = getItem('accessToken')

    if (storeduser) {
      storeduser = JSON.parse(storeduser)
    }

    if (storedAccessToken) {
      api.setAuthToken(storedAccessToken)
    }

    if (storedAccessToken && !isUserLoggedIn) {
      loginWithAccessToken(storedAccessToken)
    } else if (storedAccessToken && isUserLoggedIn) {
      loadUser()
    }

    if (storeduserProfile) {
      setProfile(storeduserProfile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}
